input, button {
  all: unset;
}

html{
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Universal Text Styles */
  font-size: medium;
  font-weight: 500;
}

/* Universal Colors */
html {
  --background: white;
  --accent: #7C1CE4;
  --offset: rgb(193, 193, 193);
  --red: rgb(173, 36, 36);
  --green: rgb(37, 199, 59);
  color: black;
}

/* Universal Component Specific Styling */

p {
  display: inline-block;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

a {
  color: var(--accent);
}

table, th, td {
  width: fit-content;
  border: 1px solid var(--offset);
  border-collapse: collapse;
}

td {
  padding: 8px;
}

footer {
  height: 6rem;
  margin-top: -6rem;
  background-color: var(--offset);
  border-top: 1px solid var(--accent);
}

input[type=text], input[type=password], input[type=number]{
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--accent);
  padding: 8px 16px;
}

button, input[type=submit]{
  border-radius: 8px;
  text-align: center;
  color: var(--background);
  background-color: var(--accent);
  padding: 8px 16px;
  box-sizing: border-box;
}

button:disabled, input[type=submit]:disabled{
  background-color: var(--offset);
}

input[type=radio] {
  display: inline-block;
  width: 9px;
  height: 9px;
  padding: 2px;
  background-clip: content-box;
  border: 2px dashed var(--accent);
  border-radius: 999px;
}

input[type=checkbox] {
  display: inline-block;
  flex: 0 0 1;
  width: 12px;
  height: 12px;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid var(--accent);
  border-radius: 4px;
}

label {
  flex: 2
}

input[type=radio]:before {
  content:'';
  display:block;
  width: 9px;
  height: 9px;   
}

label{
  display: inline-block;
}

input[type=radio]:checked, input[type=checkbox]:checked {
  background-color: var(--accent);
}

/* Universal Text Styles */
.Thin{ font-weight: 100; }
.Bold{ font-weight: 700; }
.Red{ color: var(--red); }
.Green{ color: var(--green); }
.Green-back{ background-color: var(--green); }
.Subtitle{ color: grey; }
.Title{
  font-size: x-large;
  font-weight: 500;
}
.text-center{
  text-align: center;
}
.no-wrap{
  white-space: nowrap;
}

/* Universal classes for easy styling */
.maxw{ width: 100%; }
.maxh{ height: 100%; }
.flex-col{ display: flex; flex-direction: column; }
.flex-row{ display: flex; flex-direction: row; }
.iblock{ display: inline-block; }
.bbox {box-sizing: border-box;}
.justify-center{ justify-content: center; }
.justify-between{ justify-content: space-between ; }
.justify-around{ justify-content: space-around; }
.justify-evenly{ justify-content: space-evenly; }
.align-center{ align-items: center; }
.align-start{ align-items: flex-start; }
.align-end{ align-items: flex-end; }
.self-center { align-self: center; }
.self-start { align-self: normal; }
.card{
  padding: 8px 24px;
  height:fit-content;
  background-color: var(--background);
  border-radius: 8px;
  border: 1px solid var(--offset);
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
}
.card-square{
  padding: 8px 24px;
  height:fit-content;
  background-color: var(--background);
  border: 1px solid var(--offset);
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
}
.red-dot, .green-dot{
  height: 18px;
  width: 18px;
  border-radius: 9999px;
  display: inline-block;
}

.red-dot{
  background-color: var(--red);
}

.green-dot {
  background-color: var(--green);
}

.m-0{ margin: 0px; }
.m-2{ margin: 2px; }
.m-4{ margin: 4px; }
.m-6{ margin: 6px; }
.m-8{ margin: 8px; }
.m-10{ margin: 10px; }
.m-12{ margin: 12px; }
.m-14{ margin: 14px; }
.m-16{ margin: 16px; }
.my-0{ margin-top: 0px; margin-bottom: 0px; }
.my-2{ margin-top: 2px; margin-bottom: 2px; }
.my-4{ margin-top: 4px; margin-bottom: 4px; }
.my-6{ margin-top: 6px; margin-bottom: 6px; }
.my-8{ margin-top: 8px; margin-bottom: 8px; }
.my-10{ margin-top: 10px; margin-bottom: 10px; }
.my-12{ margin-top: 12px; margin-bottom: 12px; }
.my-14{ margin-top: 14px; margin-bottom: 14px; }
.my-16{ margin-top: 16px; margin-bottom: 16px; }
.mx-0{ margin-left: 0px; margin-right: 0px; }
.mx-2{ margin-left: 2px; margin-right: 2px; }
.mx-4{ margin-left: 4px; margin-right: 4px; }
.mx-6{ margin-left: 6px; margin-right: 6px; }
.mx-8{ margin-left: 8px; margin-right: 8px; }
.mx-10{ margin-left: 10px; margin-right: 10px; }
.mx-12{ margin-left: 12px; margin-right: 12px; }
.mx-14{ margin-left: 14px; margin-right: 14px; }
.mx-16{ margin-left: 16px; margin-right: 16px; }

.p-0{ padding: 0px; }
.p-2{ padding: 2px; }
.p-4{ padding: 4px; }
.p-6{ padding: 6px; }
.p-8{ padding: 8px; }
.p-10{ padding: 10px; }
.p-12{ padding: 12px; }
.p-14{ padding: 14px; }
.p-16{ padding: 16px; }
.py-0{ padding-top: 0px; padding-bottom: 0px; }
.py-2{ padding-top: 2px; padding-bottom: 2px; }
.py-4{ padding-top: 4px; padding-bottom: 4px; }
.py-6{ padding-top: 6px; padding-bottom: 6px; }
.py-8{ padding-top: 8px; padding-bottom: 8px; }
.py-10{ padding-top: 10px; padding-bottom: 10px; }
.py-12{ padding-top: 12px; padding-bottom: 12px; }
.py-14{ padding-top: 14px; padding-bottom: 14px; }
.py-16{ padding-top: 16px; padding-bottom: 16px; }
.px-0{ padding-left: 0px; padding-right: 0px; }
.px-2{ padding-left: 2px; padding-right: 2px; }
.px-4{ padding-left: 4px; padding-right: 4px; }
.px-6{ padding-left: 6px; padding-right: 6px; }
.px-8{ padding-left: 8px; padding-right: 8px; }
.px-10{ padding-left: 10px; padding-right: 10px; }
.px-12{ padding-left: 12px; padding-right: 12px; }
.px-14{ padding-left: 14px; padding-right: 14px; }
.px-16{ padding-left: 16px; padding-right: 16px; }

.mt-0{ margin-top: 0px; }
.mr-0{ margin-right: 0px; }
.ml-0{ margin-left: 0px; }
.mb-0{ margin-bottom: 0px; }