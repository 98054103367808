#logoImg {
    height: 10vh;
    margin-top: 16px;
}

#Action{
    font-size: 5vh;
    line-height: 4vh;
}

#Year{
    line-height: 4vh;
    font-size: 5vh;
    color: var(--accent);
    padding: 0;
    margin: 4px;
}

#contentWrapper{
    max-width: 1000px;
    margin: 0 auto 0 auto;
    box-sizing: border-box;
    padding-bottom: 7rem;
    min-height: 100vh;
}

#toastBar{
    background-color: rgb(243, 154, 154);
    border-bottom: 2px solid rgb(208, 70, 70);
    padding: 8px;
    position: sticky;
    top: 0;
    left: 0;
}